import React from 'react';
import style from './index.module.scss';
import Dialog from '@material-ui/core/Dialog';
import { Card, CardContent, DialogContent, Tab, Tabs } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useUiStore, useTransactionStore } from '../../contexts/store';
import { TransactionStepCollapsible } from '../../components/transaction-step-collapsible';
import { TabPanel } from '../../components/modal-tab-panel';
import { TransactionInfoContainer } from '../../components/transaction-info';
import { Link } from '@material-ui/icons';
import { formatTransactionUrl } from '../../utils/helpers';

export interface ModalProps {
    open: boolean;
    onClose: () => void;
}

export const TransactionInfoModal = observer((props: ModalProps) => {
    const { open, onClose } = props;
    const { transactionDetail } = useTransactionStore();
    const uiStore = useUiStore();
    const { modalTab } = uiStore;

    const handleTabChange = async (
        event: React.ChangeEvent<{}>,
        tabIndex: number
    ) => {
        await uiStore.setModalTab(tabIndex);
    };

    const handleLink = () => {
        const transactionUrl = formatTransactionUrl(transactionDetail);
        navigator.clipboard.writeText(transactionUrl);
        uiStore.showSuccess('Transaction link copied.');
    };

    const renderSteps = () => {
        return transactionDetail.steps?.length ? (
            transactionDetail.steps?.map((step) => (
                <TransactionStepCollapsible key={step.id} step={step} />
            ))
        ) : (
            <div className={style.push_message}>
                No push notification found.
            </div>
        );
    };

    return (
        <Dialog
            maxWidth="lg"
            fullWidth
            onClose={onClose}
            aria-labelledby="dialog-title"
            open={open}
        >
            <div className={style.tabs}>
                <Tabs
                    value={modalTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                >
                    <Tab label="Infos" />
                    <Tab label="Logs" />
                </Tabs>
            </div>
            <TabPanel value={modalTab} index={0}>
                <div className={style.tabs_buffer}></div>
                <DialogContent>
                    <div className={style.section_title}>
                        Transaction informations{' '}
                        <div
                            className={style.icon_container}
                            onClick={handleLink}
                        >
                            <Link className={style.link_icon} />
                        </div>
                    </div>
                    <Card>
                        <CardContent>
                            <TransactionInfoContainer
                                transaction={transactionDetail}
                            />
                        </CardContent>
                    </Card>
                </DialogContent>
            </TabPanel>
            <TabPanel value={modalTab} index={1}>
                <div className={style.tabs_buffer}></div>
                <DialogContent>
                    <div className={style.section_title}>Logs</div>
                    {renderSteps()}
                    <div className={style.tip}>
                        *For more details about this transaction's logged
                        events, visit the{' '}
                        <a
                            href="https://portal.azure.com/#@MyCoucheTard.onmicrosoft.com/resource/subscriptions/eb22f298-bca8-4ffe-befd-a29e8f3f7759/resourceGroups/ngrp-prod-expresslane-rg/providers/Microsoft.Insights/components/expresslane-insights-node/searchV1"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            application insights transaction search tool
                        </a>
                        , make sure the appropriate time period is selected and
                        use the transaction's id or process id in the search
                        bar.
                    </div>
                </DialogContent>
            </TabPanel>
        </Dialog>
    );
});
