import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { TransactionsScreen } from './screens/transactions';
import { Login } from './screens/login';
import { ProtectedRoute } from './containers/protected-route';
import 'antd/dist/antd.css';
import './easyui-components/easyui.scss';

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Switch>
                    <Route path="/login" component={Login}></Route>
                    <ProtectedRoute
                        exact
                        path="/transactions/:transactionId"
                        component={TransactionsScreen}
                    />
                    <ProtectedRoute
                        exact
                        path="/"
                        component={TransactionsScreen}
                    />
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;
