import React from 'react';
import { Transaction } from '../../types/types';
import { formatDate, formatTransactionType } from '../../utils/helpers';
import { TransactionInfoItem } from '../transaction-info-item';
import {
    Block,
    Cached,
    CheckCircleOutline,
    DriveEta,
    LocalGasStation,
    Person,
    Place,
    ShoppingCart,
    Stars,
    Timer,
    Today,
} from '@material-ui/icons';

const renderCancelReason = (transaction: Transaction) => {
    if (transaction.reason)
        return (
            <TransactionInfoItem title="Cancel reason" icon={<Block />}>
                {transaction.reason}
            </TransactionInfoItem>
        );
    else return <div></div>;
};

interface InfoContainerProps {
    transaction: Transaction;
}

export const TransactionInfoContainer = (props: InfoContainerProps) => {
    const plate = props.transaction.plate
        ? props.transaction.plate
        : 'No plate found';

    const rating = props.transaction.rating
        ? `${props.transaction.rating}/10`
        : 'No user rating';

    const isRatingBad = props.transaction.rating
        ? props.transaction.rating < 5
        : false;

    return (
        <div>
            <TransactionInfoItem title="Transaction ID" icon={<ShoppingCart />}>
                {props.transaction.id}
            </TransactionInfoItem>
            <TransactionInfoItem title="Date" icon={<Today />}>
                {formatDate(props.transaction.date)}
            </TransactionInfoItem>
            <TransactionInfoItem title="User ID" icon={<Person />}>
                {props.transaction.ckid}
            </TransactionInfoItem>
            <TransactionInfoItem title="Process ID" icon={<Cached />}>
                {props.transaction.processId}
            </TransactionInfoItem>
            <TransactionInfoItem title="Site ID" icon={<Place />}>
                {props.transaction.siteId}
            </TransactionInfoItem>
            <TransactionInfoItem title="Status" icon={<CheckCircleOutline />}>
                {props.transaction.status}
            </TransactionInfoItem>
            <TransactionInfoItem
                title="Process type"
                icon={<LocalGasStation />}
            >
                {formatTransactionType(props.transaction.type)}
            </TransactionInfoItem>
            <TransactionInfoItem
                title="Vehicle licence plate"
                icon={<DriveEta />}
            >
                {plate}
            </TransactionInfoItem>
            <TransactionInfoItem
                title="User rating"
                icon={<Stars />}
                alert={isRatingBad}
            >
                {rating}
            </TransactionInfoItem>
            {renderCancelReason(props.transaction)}
            <TransactionInfoItem
                title="Time to release pump"
                icon={<Timer />}
                isLast
            >
                {props.transaction.timeToRelease}
            </TransactionInfoItem>
        </div>
    );
};
