import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/auth';
import { StoreProvider } from './contexts/store';

ReactDOM.render(
    <AuthProvider>
        <StoreProvider>
            <App />
        </StoreProvider>
    </AuthProvider>,
    document.getElementById('root')
);

reportWebVitals();
