import { TableColumn } from '../types/types';

export const Endpoints = {
    id: '/transactions/by-id',
    site: '/transactions/by-site',
    ckid: '/transactions/by-ckid',
    licencePlate: '/transactions/by-plate',
    getPlate: '/users/plates',
    getRating: '/transactions/ratings',
    logs: '/logs',
};

export const columns: TableColumn[] = [
    { id: 'id', label: 'Transaction ID', minWidth: 170 },
    { id: 'ckid', label: 'CKID', minWidth: 170 },
    { id: 'date', label: 'Date (UTC)', minWidth: 100 },
    { id: 'siteId', label: 'Site ID', minWidth: 75 },
    { id: 'status', label: 'Status', minWidth: 100 },
];

export const PAGINATION_OPTIONS = [10, 25, 100];
export const ALERT_AUTOHIDE_DASHBOARD = 3500;
export const ALERT_AUTOHIDE_LOGIN = 10000;
export const PUSH_NOTIFICATIONS_TAB_INDEX = 1;
export const JS_TIMESTAMP_MULTIPLIER = 1000;
export const ONE_WEEK = 7;
