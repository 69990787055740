import React, { FormEvent, useState } from 'react';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import style from './index.module.scss';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { useAuth } from '../../contexts/auth';
import { Redirect } from 'react-router';
import { AlertSnackbar } from '../../containers/alert-snack';
import { useUiStore } from '../../contexts/store';
import { ALERT_AUTOHIDE_LOGIN } from '../../utils/constants';
import { Input, Button } from '../../easyui-components';

export const Login = () => {
    const uiStore = useUiStore();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailMissing, setEmailMissing] = useState(false);
    const [passwordMissing, setPasswordMissing] = useState(false);
    const auth = useAuth();

    const signIn = async () => {
        setEmailMissing(false);
        setPasswordMissing(false);
        uiStore.setDisplayAlert(false);

        if (email && password) {
            try {
                await auth.login(email, password);
            } catch (err) {
                uiStore.showError(uiStore.getLoginErrorMessage(err));
            }
        } else {
            if (!email) setEmailMissing(true);
            if (!password) setPasswordMissing(true);
        }
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        console.log('coucou');
    };

    const renderSignIn = () => {
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={style.paper}>
                    <form className={style.form} onSubmit={handleSubmit}>
                        <div className={style.title}>
                            <h1 className="h1-header">Sign in</h1>
                        </div>
                        <div className={style.textfield}>
                            <span>Email</span>
                            <Input
                                value={email}
                                name="email"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </div>
                        <div className={style.textfield}>
                            <span>Password</span>
                            <Input
                                value={password}
                                name="password"
                                type="password"
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                        </div>
                        <Button type="primary" onClick={signIn}>
                            Sign In
                        </Button>
                    </form>
                </div>
                <AlertSnackbar
                    duration={ALERT_AUTOHIDE_LOGIN}
                    position="center"
                />
            </Container>
        );
    };

    const redirect = () => {
        return <Redirect to="/" />;
    };

    return <div>{!auth.user ? renderSignIn() : redirect()}</div>;
};
