export const config = {
    // Firebase Auth
    firebaseApiKey: 'AIzaSyDhYc7yzkoP8Tj5NY3t1koXlsYztQddxgQ',
    firebaseAuthDomain: 'circle-k-innovation.firebaseapp.com',
    firebaseDbUrl: 'https://circle-k-innovation.firebaseio.com',
    firebaseProjectId: 'circle-k-innovation',
    firebaseStorageBucket: 'circle-k-innovation.appspot.com',
    firebaseMessagingSenderId: '788287868734',
    firebaseAppId: '1:788287868734:web:f7bdaae2cfb910a0cf266f',

    // API
    apiUrl: process.env.REACT_APP_API_URL,

    // App
    appUrl: 'https://easyfuel-insights.couche-tard.net',
};

export default config;
