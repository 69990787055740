import React, { useEffect } from 'react';
import style from './index.module.scss';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import { TransactionSearchBar } from '../../containers/transaction-search-bar';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { TransactionInfoModal } from '../transaction-info-modal';
import { observer } from 'mobx-react';
import { useUiStore, useTransactionStore } from '../../contexts/store';
import { AlertSnackbar } from '../../containers/alert-snack';
import {
    ALERT_AUTOHIDE_DASHBOARD,
    PAGINATION_OPTIONS,
} from '../../utils/constants';
import { useLocation, useParams } from 'react-router';
import * as queryString from 'query-string';
import { TransactionTable } from '../../containers/transaction-table';

export const TransactionsScreen = observer(() => {
    const transactionStore = useTransactionStore();
    const uiStore = useUiStore();
    const { transactions } = transactionStore;
    const { isTableLoading, isModalLoading, displayModal, page, rowsPerPage } =
        uiStore;
    const { transactionId } = useParams<{ transactionId: string }>();
    const { search } = useLocation();
    const urlParams = queryString.parse(search);

    // Initial transaction rendering if id is passed in url
    useEffect(() => {
        if (transactionId && urlParams.ts) {
            const ts = Number(urlParams.ts);
            transactionStore.handleTransactionUrl(transactionId, ts);
        }
    }, [transactionId]); // eslint-disable-line react-hooks/exhaustive-deps

    const renderLoader = () => {
        return (
            <div className={style.loader_container}>
                <CircularProgress />
            </div>
        );
    };

    return (
        <>
            <div className={style.top_content}>
                <h1 className="h1-header">Transactions</h1>
                <TransactionSearchBar />
            </div>
            {isTableLoading ? renderLoader() : <TransactionTable />}
            {!isTableLoading && transactionStore.transactions.length !== 0 && (
                <TablePagination
                    rowsPerPageOptions={PAGINATION_OPTIONS}
                    component="div"
                    count={transactions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={(e, page) => uiStore.setPage(page)}
                    onChangeRowsPerPage={(e) =>
                        uiStore.setRowsPerPage(+e.target.value)
                    }
                />
            )}
            <TransactionInfoModal
                open={displayModal}
                onClose={() => uiStore.setDisplayModal(false)}
            />
            <Backdrop className={style.backdrop} open={isModalLoading}>
                <CircularProgress color="primary" />
            </Backdrop>
            <AlertSnackbar
                duration={ALERT_AUTOHIDE_DASHBOARD}
                position="left"
            />
            <AlertSnackbar
                duration={ALERT_AUTOHIDE_DASHBOARD}
                position="left"
                success
            />
        </>
    );
});
