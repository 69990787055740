import React from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import moment from 'moment';
import style from './style.module.scss';

export const DatePicker = React.memo(function DatePicker(props: {
    label: string;
    hasError: boolean;
    value: string;
    onChange: (value?: string) => void;
    disabled?: boolean;
    disableIfBefore?: string;
}) {
    const momentValue = moment(props.value);
    let disableThreshold: moment.Moment | null = null;

    // This feels weird, but the goal is to help the caller benefit of the memoization. Without the check, the caller would
    // need to handle a never changing date value
    if (props.disableIfBefore === 'now') {
        disableThreshold = moment();
    } else if (props.disableIfBefore) {
        disableThreshold = moment(props.disableIfBefore);
    }

    return (
        <div className={style.date_picker_holder}>
            <AntDatePicker
                allowClear={false}
                format="YYYY-MM-DD"
                value={momentValue}
                onChange={(e) => props.onChange(e?.toISOString())}
                disabledDate={(date) => {
                    if (!disableThreshold) {
                        return false;
                    }
                    return date.isSameOrBefore(disableThreshold);
                }}
                dropdownClassName={style.calendar}
                disabled={props.disabled}
            />
        </div>
    );
});
