import React from 'react';
import style from './index.module.scss';
import { Divider } from '@material-ui/core';
import { TransactionType } from '../../types/types';

interface InfoProps {
    title: string;
    icon?: React.ReactNode;
    children: string | number | TransactionType;
    isLast?: boolean;
    alert?: boolean;
}

export const TransactionInfoItem = (props: InfoProps) => {
    const renderDivider = () => {
        return props.isLast ? <div></div> : <Divider variant="middle" />;
    };

    const infoStyle = () => {
        return props.alert ? style.alert : style.info;
    };

    return (
        <div className={style.info_container}>
            <div>
                <div className={style.icon}>{props.icon}</div>
                <div className={style.icon_buffer}></div>
                <div className={style.title}>{props.title}</div>
            </div>
            <div className={infoStyle()}>{props.children}</div>
            {renderDivider()}
        </div>
    );
};
