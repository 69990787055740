import React from "react";
import style from "./style.module.scss";

type InputValue = string | number | string[] | undefined;

interface InputProps {
    name?: string;
    value: InputValue;
    type?: string;
    placeholder?: string;
    allowClear?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    fullWidth?: boolean;
    width?: number;
}

export const Input = React.memo(function Input(props: InputProps) {
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    return (
        <span className={style.input_wrapper}>
            {props.allowClear && (props.value || props.value === 0) && (
                <svg
                    width="10"
                    height="10"
                    viewBox="0 0 24 24"
                    onClick={() => {
                        if (inputRef.current) {
                            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                                window.HTMLInputElement.prototype,
                                "value"
                            )?.set;
                            nativeInputValueSetter?.call(inputRef.current, "");
                            inputRef.current.dispatchEvent(
                                new Event("input", { bubbles: true })
                            );
                        }
                    }}
                >
                    <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
                </svg>
            )}
            <input
                ref={inputRef}
                placeholder={props.placeholder}
                style={{
                    width: props.width
                        ? props.width
                        : props.fullWidth
                        ? "100%"
                        : 250,
                }}
                type={props.type}
                name={props.name}
                value={props.value}
                className={style.custom_input}
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
        </span>
    );
});
