import { makeAutoObservable } from 'mobx';
import { RootStore } from './root';

export class UiStore {
    isTableLoading: boolean = false;
    isModalLoading: boolean = false;
    displayModal: boolean = false;
    displayAlert: boolean = false;
    displaySuccess: boolean = false;
    alertMessage: string = '';
    successMessage: string = '';
    modalTab: number = 0;
    page: number = 0;
    rowsPerPage: number = 10;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setIsTableLoading(loading: boolean) {
        this.isTableLoading = loading;
    }

    setIsModalLoading(loading: boolean) {
        this.isModalLoading = loading;
    }

    setDisplayModal(display: boolean) {
        this.displayModal = display;
        if (!display) this.setModalTab(0);
    }

    setDisplayAlert(display: boolean) {
        this.displayAlert = display;
    }

    setDisplaySuccess(display: boolean) {
        this.displaySuccess = display;
    }

    setAlertMessage(message: string) {
        this.alertMessage = message;
    }

    setSuccessMessage(message: string) {
        this.successMessage = message;
    }

    setModalTab(tab: number) {
        this.modalTab = tab;
    }

    setPage(page: number) {
        this.page = page;
    }

    setRowsPerPage(rowsPerPage: number) {
        this.rowsPerPage = rowsPerPage;
        this.setPage(0);
    }

    showError(err: any) {
        let errorMessage;
        if (typeof err === 'string') {
            errorMessage = err;
        } else {
            errorMessage = err.response?.data.message
                ? err.response.data.message
                : err.message;
        }
        this.setAlertMessage(errorMessage);
        this.setDisplayAlert(true);
    }

    showSuccess(message: string) {
        this.setSuccessMessage(message);
        this.setDisplaySuccess(true);
    }

    // Formats custom error message in function of firebase auth error
    getLoginErrorMessage(err: any): string {
        switch (err.code) {
            case 'auth/invalid-email':
                return err.message;
            case 'auth/wrong-password':
                return err.message;
            case 'auth/user-not-found':
                return 'Invalid email address or password.';
            default:
                return 'An error has occured while attempting to sign in.';
        }
    }
}
