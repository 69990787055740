import firebase from 'firebase/app';
import axios from 'axios';
import 'firebase/auth';
import config from '../utils/config';

class AuthService {
    firebaseAuth = firebase
        .initializeApp({
            apiKey: config.firebaseApiKey,
            authDomain: config.firebaseAuthDomain,
            projectId: config.firebaseProjectId,
            storageBucket: config.firebaseStorageBucket,
            messagingSenderId: config.firebaseMessagingSenderId,
            appId: config.firebaseAppId,
        })
        .auth();

    async setUser(user: firebase.User | null) {
        if (user) {
            const userToken = await this.firebaseAuth.currentUser?.getIdToken();
            axios.defaults.headers['x-access-firebase-token'] = userToken;
        }
    }

    async refreshToken() {
        const userToken = await this.firebaseAuth.currentUser?.getIdToken(true);
        axios.defaults.headers['x-access-firebase-token'] = userToken;
    }
}

export default new AuthService();
