import * as React from 'react';
import style from './index.module.scss';
import { Select } from 'antd';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useTransactionStore } from '../../contexts/store';
import { SearchParameter } from '../../types/types';
import {
    Select as EasySelect,
    Input,
    DatePicker,
    Button,
} from '../../easyui-components';
import moment from 'moment';

export const TransactionSearchBar = observer(() => {
    const [searchParameter, setSearchParameter] =
        useState<SearchParameter>('id');
    const [searchInput, setSearchInput] = React.useState('');
    const [startDate, setStartDate] = React.useState(
        moment().subtract(1, 'week').startOf('day').toISOString()
    );
    const [endDate, setEndDate] = React.useState(
        moment().startOf('day').toISOString()
    );
    const transactionStore = useTransactionStore();

    const renderSearchLabel = () => {
        switch (searchParameter) {
            case 'id':
                return 'Enter Transaction ID...';
            case 'site':
                return 'Enter Site ID...';
            case 'licencePlate':
                return 'Enter Licence Plate...';
            case 'ckid':
                return 'Enter CKID...';
            default:
                return 'Search Value';
        }
    };

    return (
        <div className={style.grid}>
            <span>Search Field</span>
            <span>{renderSearchLabel()}</span>
            <span>From</span>
            <span>To</span>
            <div>
                <EasySelect
                    width={250}
                    value={searchParameter}
                    onChange={(value) =>
                        setSearchParameter(value as SearchParameter)
                    }
                    placeholder="Search by..."
                >
                    <Select.Option value="id">Transaction Id</Select.Option>
                    <Select.Option value="site">Site Id</Select.Option>
                    <Select.Option value="licencePlate">
                        License Plate
                    </Select.Option>
                    <Select.Option value="ckid">CKID</Select.Option>
                </EasySelect>
            </div>
            <div>
                <Input
                    width={350}
                    value={searchInput}
                    allowClear={true}
                    onChange={(event) => setSearchInput(event.target.value)}
                />
            </div>

            <div>
                <DatePicker
                    value={startDate}
                    label="Start"
                    hasError={false}
                    onChange={(date) => setStartDate(date ?? '')}
                    disabled={false}
                />
            </div>

            <div>
                <DatePicker
                    value={endDate}
                    hasError={false}
                    label="End"
                    onChange={(date) => setEndDate(date ?? '')}
                    disableIfBefore={startDate}
                    disabled={false}
                />
            </div>
            <div className={style.button_holder}>
                <Button
                    type="primary"
                    disabled={!searchInput}
                    onClick={() =>
                        transactionStore.searchTransactions(
                            searchParameter,
                            searchInput,
                            startDate,
                            endDate
                        )
                    }
                >
                    Search
                </Button>
            </div>
        </div>
        // <div className={style.search_container}>
        //     <div className={style.form_container}>
        //         <EasySelect
        //             width={250}
        //             value={searchParameter}
        //             onChange={(value) =>
        //                 setSearchParameter(value as SearchParameter)
        //             }
        //             placeholder="Search by..."
        //         >
        //             <Select.Option value="id">Transaction Id</Select.Option>
        //             <Select.Option value="site">Site Id</Select.Option>
        //             <Select.Option value="licencePlate">
        //                 License Plate
        //             </Select.Option>
        //             <Select.Option value="ckid">CKID</Select.Option>
        //         </EasySelect>
        //         <Input
        //             value={searchInput}
        //             onChange={(event) => setSearchInput(event.target.value)}
        //         />
        //         {renderSearchInput()}
        //     </div>
        //     <div className={style.filter_container}>
        //         <MuiPickersUtilsProvider utils={DateFnsUtil}>
        //             <KeyboardDatePicker
        //                 className={style.date_picker}
        //                 value={filter.start.toUTCString().slice(0, 16)} // Trick to display UTC day on datePicker
        //                 onChange={(date) =>
        //                     transactionStore.setFilterStart(date)
        //                 }
        //                 label="From"
        //                 maxDate={filter.end.toUTCString().slice(0, 16)}
        //                 format="yyyy-MM-dd"
        //                 inputVariant="outlined"
        //             />
        //             <KeyboardDatePicker
        //                 className={style.date_picker}
        //                 value={filter.end.toUTCString().slice(0, 16)}
        //                 onChange={(date) => transactionStore.setFilterEnd(date)}
        //                 label="To"
        //                 minDate={filter.start.toUTCString().slice(0, 16)}
        //                 format="yyyy-MM-dd"
        //                 inputVariant="outlined"
        //             />
        //         </MuiPickersUtilsProvider>
        //     </div>
        // </div>
    );
});
