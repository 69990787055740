import React from "react";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index } = props;

    return (
        <div
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
        >
            {children}
        </div>
    );
};
