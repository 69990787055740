import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';

interface ProtectedRouteProps {
    path: string;
    [rest: string]: any;
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
    const { user } = useAuth();
    return user ? <Route {...props} /> : <Redirect to="/login" />;
};
