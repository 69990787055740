import React from "react";
import { RootStore } from "../../stores/root";

const rootStore = new RootStore();
const StoreContext = React.createContext<RootStore | null>(null);

interface StoreProviderProps {
    children: React.ReactNode;
}

export const StoreProvider = ({ children }: StoreProviderProps) => {
    return (
        <StoreContext.Provider value={rootStore}>
            {children}
        </StoreContext.Provider>
    );
};

export const useStore = () => {
    const store = React.useContext(StoreContext);
    if (!store)
        throw new Error("useStore must be used within a StoreProvider.");
    return store;
};

export const useTransactionStore = () => {
    const store = useStore();
    return store.transactionStore;
};

export const useUiStore = () => {
    const store = useStore();
    return store.uiStore;
};

export default rootStore;
