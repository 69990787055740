import axios from 'axios';
import {
    Transaction,
    TransactionStep,
    SearchParameter,
    TimeFilter,
} from '../types/types';
import config from '../utils/config';
import authService from './auth.service';
import { Endpoints, JS_TIMESTAMP_MULTIPLIER } from '../utils/constants';
import { StatusCodes } from 'http-status-codes';

export default class DataHandlerService {
    constructor() {
        axios.defaults.baseURL = config.apiUrl;
    }

    async fetchTransactions(
        searchParam: SearchParameter,
        searchInput: string,
        filter: TimeFilter
    ): Promise<Transaction[]> {
        const requestUrl = this.getTransactionsUrl(
            searchParam,
            searchInput,
            filter
        );
        return await this.get(requestUrl);
    }

    async fetchTransactionSteps(
        transaction: Transaction
    ): Promise<TransactionStep[]> {
        const requestUrl = this.getStepsUrl(transaction);
        return await this.get(requestUrl);
    }

    async fetchVehiclePlate(vehicleId: string): Promise<string> {
        const requestUrl = `${Endpoints.getPlate}/${vehicleId}`;
        return await this.get(requestUrl);
    }

    async fetchRating(transaction: Transaction): Promise<number> {
        const requestUrl = `${Endpoints.getRating}/${transaction.id}`;
        return await this.get(requestUrl);
    }

    // Wrapper method for axios get
    private async get(requestUrl: string) {
        try {
            const response = await axios.get(requestUrl);
            return response.data;
        } catch (err) {
            if (err.response?.status === StatusCodes.FORBIDDEN) {
                await authService.refreshToken();
                const response = await axios.get(requestUrl);
                return response.data;
            } else throw err;
        }
    }

    private getTransactionsUrl(
        searchParam: SearchParameter,
        searchInput: string,
        filter: TimeFilter
    ) {
        const filterStart = filter.start.getTime() / JS_TIMESTAMP_MULTIPLIER;
        const filterEnd = filter.end.getTime() / JS_TIMESTAMP_MULTIPLIER;
        const params = {
            start: filterStart.toString(),
            end: filterEnd.toString(),
        };
        const queryParams = new URLSearchParams(params);

        return `${
            Endpoints[searchParam]
        }/${searchInput}?${queryParams.toString()}`;
    }

    private getStepsUrl(transaction: Transaction) {
        const params = {
            processId: transaction.processId,
            date: transaction.date,
        };
        const queryParams = new URLSearchParams(params);

        return `${Endpoints.logs}/${transaction.id}?${queryParams.toString()}`;
    }
}
