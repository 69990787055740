import { makeAutoObservable, runInAction } from 'mobx';
import { SearchParameter, TimeFilter, Transaction } from '../types/types';
import {
    createTransaction,
    findProcessType,
    getDayDifference,
    getNextWeekDate,
    getPreviousWeekDate,
    getTimeToRelease,
} from '../utils/helpers';
import { RootStore } from './root';
import DataHandlerService from '../services/dataHandler.service';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { JS_TIMESTAMP_MULTIPLIER, ONE_WEEK } from '../utils/constants';
import moment from 'moment';

export class TransactionStore {
    transactions: Transaction[] = [];
    transactionDetail: Transaction = createTransaction();
    filter: TimeFilter = { start: new Date(), end: new Date() };

    rootStore: RootStore;
    private dataHandler: DataHandlerService = new DataHandlerService();

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;

        this.filter.start.setUTCDate(this.filter.start.getUTCDate() - ONE_WEEK);
        this.filter.start.setUTCHours(0, 0, 0, 0);
        this.filter.end.setUTCHours(23, 59, 59, 999);
    }

    async searchTransactions(
        searchParam: SearchParameter,
        searchInput: string,
        startDate: string,
        endDate: string
    ) {
        this.rootStore.uiStore.setIsTableLoading(true);
        try {
            const transactions = await this.dataHandler.fetchTransactions(
                searchParam,
                searchInput,
                {
                    start: moment(startDate).utc(true).toDate(),
                    end: moment(endDate).utc(true).toDate(),
                }
            );
            runInAction(() => (this.transactions = transactions));
        } catch (err) {
            runInAction(() => (this.transactions = []));
            this.rootStore.uiStore.showError(err);
        } finally {
            this.rootStore.uiStore.setPage(0);
            this.rootStore.uiStore.setIsTableLoading(false);
        }
    }

    async fetchTransactionDetail(transaction: Transaction) {
        if (transaction.id === this.transactionDetail.id) {
            this.rootStore.uiStore.setDisplayModal(true);
            return;
        }
        this.rootStore.uiStore.setIsModalLoading(true);
        try {
            transaction.steps = await this.dataHandler.fetchTransactionSteps(
                transaction
            );
            transaction.plate = await this.dataHandler.fetchVehiclePlate(
                transaction.vehicleId
            );
            transaction.rating =
                Number(await this.dataHandler.fetchRating(transaction)) * 2;
            if (!transaction.type)
                transaction.type = findProcessType(transaction);
            transaction.timeToRelease = getTimeToRelease(transaction);
            runInAction(() => {
                this.transactionDetail = transaction;
            });
            this.rootStore.uiStore.setDisplayModal(true);
        } catch (err) {
            this.rootStore.uiStore.showError(err);
        } finally {
            this.rootStore.uiStore.setIsModalLoading(false);
        }
    }

    async handleTransactionUrl(transactionId: string, timestamp: number) {
        await this.searchTransactions(
            'id',
            transactionId,
            moment(timestamp).startOf('day').toISOString(),
            moment(timestamp).endOf('day').toISOString()
        );
        if (this.transactions[0])
            await this.fetchTransactionDetail(this.transactions[0]);
    }

    setFilterStart(date: MaterialUiPickersDate | Date) {
        if (!date) return;
        date.setUTCDate(date.getDate());
        date.setUTCHours(0, 0, 0, 0);
        this.filter.start = date;

        // Allows for a one week maximum time range
        if (getDayDifference(this.filter.start, this.filter.end) > ONE_WEEK) {
            const filterEndDate = getNextWeekDate(this.filter.start);
            filterEndDate.setUTCHours(23, 59, 59, 999);
            this.filter.end = filterEndDate;
        }
    }

    setFilterEnd(date: MaterialUiPickersDate | Date) {
        if (!date) return;
        date.setUTCDate(date.getDate());
        date.setUTCHours(23, 59, 59, 999);
        this.filter.end = date;

        // Allows for a one week maximum time range
        if (getDayDifference(this.filter.start, this.filter.end) > ONE_WEEK) {
            const filterStartDate = getPreviousWeekDate(this.filter.end);
            filterStartDate.setUTCHours(0, 0, 0, 0);
            this.filter.start = filterStartDate;
        }
    }

    private getFilter(timestamp: number): TimeFilter {
        const transactionDate = new Date(timestamp * JS_TIMESTAMP_MULTIPLIER);
        return {
            start: new Date(transactionDate.setUTCHours(0, 0, 0, 0)),
            end: new Date(transactionDate.setUTCHours(23, 59, 59, 999)),
        };
    }
}
