import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useUiStore } from '../../contexts/store';
import { observer } from 'mobx-react';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface snackbarProps {
    duration: number;
    position: 'left' | 'center';
    success?: boolean;
}

export const AlertSnackbar = observer((props: snackbarProps) => {
    const uiStore = useUiStore();
    const { displayAlert, alertMessage, displaySuccess, successMessage } =
        uiStore;
    const severity = props.success ? 'success' : 'error';
    const message = props.success ? successMessage : alertMessage;
    const open = props.success ? displaySuccess : displayAlert;

    const handleClose = () => {
        props.success
            ? uiStore.setDisplaySuccess(false)
            : uiStore.setDisplayAlert(false);
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: props.position,
            }}
            open={open}
            onClose={handleClose}
            autoHideDuration={props.duration}
        >
            <Alert variant="filled" severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    );
});
