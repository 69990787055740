import { TransactionStore } from "./transaction";
import { UiStore } from "./ui";

export class RootStore {
    public transactionStore: TransactionStore;
    public uiStore: UiStore;

    constructor() {
        this.transactionStore = new TransactionStore(this);
        this.uiStore = new UiStore(this);
    }
}
