import React from 'react';
import style from './index.module.scss';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Box,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { TransactionStep } from '../../types/types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const TransactionStepCollapsible = observer(
    (props: { step: TransactionStep }) => {
        const stepContainerClass =
            props.step.errorMessage || props.step.id === 7
                ? style.error_step_container
                : '';
        return (
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className={stepContainerClass}>
                        Step {props.step.id} ({props.step.name}):{' '}
                        {props.step.date}
                    </div>
                </AccordionSummary>
                <AccordionDetails className={style.accordion_details}>
                    {props.step.errorMessage ? (
                        <div>
                            <div className={style.error_message_container}>
                                Error message sent: {props.step.errorMessage}
                            </div>
                            <br />
                        </div>
                    ) : (
                        <div></div>
                    )}
                    {props.step.calls.length ? (
                        <Box margin={1}>
                            <Typography>API calls</Typography>
                            <Table size="small" aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Call endpoint</TableCell>
                                        <TableCell>Return code</TableCell>
                                        <TableCell>Timestamp [UTC]</TableCell>
                                        <TableCell>Additional data</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.step.calls.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {row.endpoint}
                                            </TableCell>
                                            <TableCell>
                                                {row.resultCode}
                                            </TableCell>
                                            <TableCell>
                                                {row.timestamp}
                                            </TableCell>
                                            <TableCell>{row.data}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    ) : (
                        <div>No related call to display</div>
                    )}
                </AccordionDetails>
            </Accordion>
        );
    }
);
