import React, { useContext, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import style from './index.module.scss';
import { CircularProgress } from '@material-ui/core';
import authService from '../../services/auth.service';

const AuthContext = React.createContext<{
    user: firebase.User | null;
    login: (email: string, password: string) => void;
}>({ user: null, login: () => {} });

export function useAuth() {
    return useContext(AuthContext);
}

interface AuthProviderProps {
    children: React.ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
    const [user, setUser] = useState<firebase.User | null>(null);
    const [loading, setLoading] = useState(true);
    const { firebaseAuth } = authService;

    useEffect(() => {
        const unsubscribe = firebaseAuth.onAuthStateChanged(async (user) => {
            setUser(user);
            await authService.setUser(user);
            setLoading(false);
        });
        return unsubscribe;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const login = async (email: string, password: string) => {
        await firebaseAuth.signInWithEmailAndPassword(email, password);
    };

    const renderLoader = () => {
        return (
            <div className={style.loader}>
                <CircularProgress />
            </div>
        );
    };

    return (
        <AuthContext.Provider value={{ user, login }}>
            {loading ? renderLoader() : children}
        </AuthContext.Provider>
    );
}
