import React from "react";
import { Select as AntdSelect, Tooltip } from "antd";
import { SelectValue, SelectProps } from "antd/es/select";
import style from "./style.module.scss";
import { RefCallBack } from "react-hook-form";

interface Props<VT> extends SelectProps<VT> {
    width?: number;
    ref?: RefCallBack;
}

export function Select<VT extends SelectValue = SelectValue>(props: Props<VT>) {
    return (
        <span className={style.custom_select}>
            <Tooltip
                placement="bottom"
                title={
                    props.disabled &&
                    "Only one input can be selected at the same time"
                }
            >
                <AntdSelect
                    allowClear={props.allowClear}
                    placeholder={props.placeholder}
                    style={{ width: props.width }}
                    value={props.value}
                    onChange={props.onChange}
                    suffixIcon={
                        <svg width="11" height="11" viewBox="0 0 24 24">
                            <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                        </svg>
                    }
                    clearIcon={
                        <svg width="10" height="10" viewBox="0 0 24 24">
                            <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
                        </svg>
                    }
                    defaultValue={props.defaultValue}
                    filterOption={false}
                    onSearch={props.onSearch}
                    notFoundContent={props.notFoundContent}
                    showSearch={props.showSearch}
                    disabled={props.disabled}
                    dropdownClassName={style.options}
                    ref={props.ref}
                >
                    {props.children}
                </AntdSelect>{" "}
            </Tooltip>
        </span>
    );
}
