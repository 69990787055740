import { Transaction, TransactionType } from '../types/types';
import config from './config';
import { JS_TIMESTAMP_MULTIPLIER } from './constants';

export function createTransaction(): Transaction {
    const transaction = {
        id: '',
        processId: '',
        ckid: '',
        date: '',
        timeStamp: 0,
        siteId: 0,
        vehicleId: '',
        type: undefined,
        status: '',
        reason: '',
        timeToRelease: '',
    };

    return transaction;
}

export function findProcessType(
    transaction: Transaction
): TransactionType | undefined {
    if (!transaction.steps) return undefined;
    const manualStartStep = transaction.steps.find((step) => step.id === 9);
    if (manualStartStep?.calls.length) return 'one-touch';
    if (transaction.vehicleId === 'manual') return 'manual-pump-selection';
    return 'frictionless';
}

export function getTimeToRelease(transaction: Transaction): string {
    const releaseTime = transaction.steps?.find((step) => step.id === 3);
    if (!releaseTime) return 'No release';

    const releaseEvent = new Date(releaseTime.date);
    const creationEvent = new Date(transaction.date);

    return (
        ((releaseEvent.getTime() - creationEvent.getTime()) / 1000).toString() +
        ' seconds'
    );
}

export function formatDate(date: string): string {
    date = date.slice(0, -5);
    return date.replace('T', ' at ');
}

export function formatTransactionType(type: TransactionType | undefined) {
    if (!type) return 'undefined';
    return type;
}

export function formatTransactionUrl(transaction: Transaction): string {
    const date = new Date(transaction.date);
    const timestamp = Math.floor(date.getTime() / JS_TIMESTAMP_MULTIPLIER);

    return `${config.appUrl}/transactions/${transaction.id}?ts=${timestamp}`;
}

export function getDayDifference(date1: Date, date2: Date): number {
    const timeDifference = Math.abs(date1.getTime() - date2.getTime());
    return timeDifference / (JS_TIMESTAMP_MULTIPLIER * 3600 * 24);
}

export function getPreviousWeekDate(date: Date): Date {
    const newDate = new Date(date);
    newDate.setTime(
        newDate.getTime() - 7 * 24 * 3600 * JS_TIMESTAMP_MULTIPLIER
    );
    return newDate;
}

export function getNextWeekDate(date: Date): Date {
    const newDate = new Date(date);
    newDate.setTime(
        newDate.getTime() + 7 * 24 * 3600 * JS_TIMESTAMP_MULTIPLIER
    );
    return newDate;
}
