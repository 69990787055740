import React from 'react';
import style from './index.module.scss';
import { observer } from 'mobx-react';
import {
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';
import { columns } from '../../utils/constants';
import { useTransactionStore, useUiStore } from '../../contexts/store';
import { TableColumn, Transaction } from '../../types/types';
import { ErrorOutline } from '@material-ui/icons';
import { formatDate } from '../../utils/helpers';

export const TransactionTable = observer(() => {
    const transactionStore = useTransactionStore();
    const { transactions } = transactionStore;
    const { page, rowsPerPage } = useUiStore();

    const getRows = () => {
        const firstIndex = page * rowsPerPage;
        const lastIndex = page * rowsPerPage + rowsPerPage;
        return transactions.slice(firstIndex, lastIndex);
    };

    const formatCellContent = (
        column: TableColumn,
        value: string | number | undefined
    ) => {
        if (column.id === 'date' && typeof value === 'string')
            return formatDate(value);
        if (
            column.id === 'status' &&
            value !== 'cancelled' &&
            value !== 'service_financed'
        ) {
            return (
                <div className={style.icon_container}>
                    {value}
                    <ErrorOutline
                        className={style.icon}
                        fontSize="small"
                        color="error"
                    />
                </div>
            );
        }
        return value;
    };

    const openModal = async (transaction: Transaction) => {
        const selection = window.getSelection();
        if (!selection?.toString()) {
            await transactionStore.fetchTransactionDetail(transaction);
        }
    };

    return (
        <TableContainer className={style.table_container}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getRows().map((row) => {
                        return (
                            <TableRow
                                className={style.row}
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                                onClick={() => openModal(row)}
                            >
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell
                                            align={column.align}
                                            key={column.id}
                                        >
                                            {formatCellContent(column, value)}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
});
